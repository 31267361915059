import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import {
  ButtonBase,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import pinkPurpleLogo from '@aspida/react-components/dist/pinkPurple.svg';
import { Auth } from 'aws-amplify';
import RouteButton from 'components/routeButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Trophy from 'assets/images/trophy.svg';
import './styles.css';

toast.configure();

export default class AspidaForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ['forgotPassword']; // eslint-disable-line no-underscore-dangle
    this.setDefaults();
  }

  setDefaults = () => {
    this.setState(() => ({
      delivery: null,
      code: '',
      password: '',
      confirmPassword: '',
      username: '',
      completed: false,
    }));
  };

  handleClickReturnToSignIn = () => {
    this.setDefaults();
    this.changeState('signIn');
  };

  sendCode = () => {
    Auth.forgotPassword(this.state.username)
      .then(() => {
        this.setState(() => ({ delivery: true }));
      }).catch(() => {
        this.setState(() => ({ delivery: true }));
      });
  };

  handleClickSubmitNewPassword = async () => {
    if (this.state.password !== this.state.confirmPassword) {
      toast.error('Passwords do not match!');
      return;
    }
    Auth.forgotPasswordSubmit(this.state.username || sessionStorage.getItem("__email"), this.state.code,
      this.state.password)
      .then(() => {
        this.setState({ completed: true });
      }).catch((error) => {
        toast.error(error.message);
      });
  };

  handleMouseDownPassword = event => event.preventDefault();

  handleClickShowPassword = () => {
    this.setState(state => ({
      showPassword: !state.showPassword,
    }));
  };

  handleChangeLowercase = name => event => {
    this.setState({ [name]: event.target.value.toLowerCase() });
    this.inputs[name] = event.target.value.toLowerCase();
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.inputs[name] = event.target.value;
  };

  sendView() {
    return (
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Typography variant="body1" className="explanation">Enter email for password reset</Typography>
          <FormControl variant="outlined" className="input" fullWidth>
            <InputLabel htmlFor="username" className="label">
              Email Address
            </InputLabel>
            <OutlinedInput
              id="username"
              label="Username"
              inputProps={{
                key: 'username',
                name: 'username',
              }}
              autoFocus
              onChange={this.handleChangeLowercase('username')}
              style={{ paddingRight: '14px' }}
              labelWidth={105}
            />
            <FormHelperText>
              <ButtonBase className="reset" onClick={() => this.changeState('signIn')}>
                Back to Sign In
              </ButtonBase>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <RouteButton disabled={this.state.loading} type="submit" onClick={() => this.sendCode()}>
            Send code
          </RouteButton>
        </Grid>
      </Grid>
    );
  }

  submitView() {
    const { showPassword, code } = this.state;
    return (
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Typography variant="h6" color="inherit">
            If the account exists,<br />
            an email will be sent to the email address.
          </Typography>
          <Typography variant="body2" color="inherit">
            (Check your spam.)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className="explanation">Verify Code</Typography>
          <FormControl variant="outlined" className="input" fullWidth>
            <NumberFormat
              name="code"
              key="code"
              id="code"
              inputProps={{
                key: 'code',
                name: 'code',
              }}
              customInput={TextField}
              format="######"
              label="6-Digit Code"
              variant="outlined"
              value={code}
              onChange={this.handleChange('code')}
            />
          </FormControl>
          <FormHelperText>
            <ButtonBase className="reset" onClick={this.sendCode}>
              Resend Code
            </ButtonBase>
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className="explanation">Set a new password</Typography>
          <FormControl variant="outlined" className="input" fullWidth>
            <InputLabel htmlFor="password" className="label">
              Password
            </InputLabel>
            <OutlinedInput
              id="password"
              inputProps={{
                key: 'password',
                name: 'password',
              }}
              type={showPassword ? 'text' : 'password'}
              onChange={this.handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle Password Visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={74}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className="input" fullWidth>
            <InputLabel htmlFor="password" className="label">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="confirmPassword"
              inputProps={{
                key: 'confirmPassword',
                name: 'confirmPassword',
              }}
              type={showPassword ? 'text' : 'password'}
              onChange={this.handleChange('confirmPassword')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle Password Visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={74}
            />
          </FormControl>
          <FormHelperText>
            <ButtonBase className="reset" onClick={this.handleClickReturnToSignIn}>
              Back to Sign In
            </ButtonBase>
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <RouteButton
            disabled={this.state.loading}
            onClick={this.handleClickSubmitNewPassword}
          >
            Update Password
          </RouteButton>
        </Grid>
      </Grid>
    );
  }

  successfullyUpdated() {
    return (
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Typography variant="h4" className="successText">
            <strong>Password was<br />successfully updated!</strong>
          </Typography>
          <img src={Trophy} alt="completed" className="trophy" />
        </Grid>
        <Grid item xs={12}>
          <RouteButton onClick={this.handleClickReturnToSignIn}>
            Go to Login Screen
          </RouteButton>
        </Grid>
      </Grid>
    );
  }

  resolveView() {
    const { authData = {} } = this.props;
    if (this.state.completed) {
      return this.successfullyUpdated();
    }
    return this.state.delivery || authData.username ? this.submitView() : this.sendView();
  }

  showComponent() {
    return (
      <div>
        <img src={pinkPurpleLogo} className="logo" alt="logo" />
        <Typography variant="body1" color="inherit">
          We're in the business of protecting dreams.
        </Typography>
        {this.resolveView()}
      </div>
    );
  }
}
