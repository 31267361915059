import { createTheme } from '@material-ui/core/styles';

const LIGHT = 300;

const theme = createTheme({
  typography: {
    fontFamily: ['Muli', 'sans-serif'].join(','),
  },
});

theme.typography.h4['font-weight'] = LIGHT;
theme.typography.h3['font-weight'] = LIGHT;
theme.typography.h5['font-weight'] = LIGHT;
theme.typography.body1['font-weight'] = LIGHT;

export default theme;
