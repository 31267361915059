/* eslint-disable no-underscore-dangle */
import React from 'react';
import { ConfirmSignIn } from 'aws-amplify-react';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import pinkPurpleLogo from '@aspida/react-components/dist/pinkPurple.svg';
import { Auth } from 'aws-amplify';
import RouteButton from 'components/routeButton';

import './styles.css';

export default class AspidaConfirmSignIn extends ConfirmSignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['confirmSignIn'];
  }

  state = {
    loading: false,
    code: '',
    user: null,
    rememberDevice: true,
  };

  setCode = code => {
    this.setState({
      code,
    });
  };

  onSubmit = async event => {
    event.preventDefault();
    this.inputs.code = this.state.code;

    localStorage.setItem('optOutRememberDevice', !this.state.rememberDevice);
    this.setState({ loading: true });
    await this.confirm();
    this.setState({ loading: false });
  };

  goBack = () => {
    Auth.signOut();
  };

  showComponent() {
    const { code } = this.state;

    let instructionBox = (
      <Typography className="twoStepMessage" variant="body1" color="inherit">
        Thanks for keeping your account secure.
        <br />
        Open the authenticator app on your device &nbsp;
      </Typography>
    );

    if (this.props.authData.challengeName !== 'SOFTWARE_TOKEN_MFA') {
      const dest = this.props.authData.challengeParam.CODE_DELIVERY_DESTINATION;
      const phoneString = `(xxx)-xxx-${dest.substring(dest.length - 4, dest.length)}`;
      instructionBox = (
        <Typography className="twoStepMessage" variant="body1" color="inherit">
          Thanks for keeping your account secure.
          <br />
          Check your mobile device:&nbsp;<strong>{phoneString}</strong>
        </Typography>
      );
    }

    return (
      <form onSubmit={this.onSubmit.bind(this)} className="root">
        <img src={pinkPurpleLogo} className="logo" alt="logo" />
        <Typography className="twoStepHeader" variant="h5" color="inherit">
          Two-step Verification
        </Typography>
        {instructionBox}
        <Grid container spacing={1} direction="column" justify="center" alignItems="center">
          <Grid item xs={12}>
            <FormControl variant="outlined" className="input">
              <NumberFormat
                name="code"
                customInput={TextField}
                format="######"
                label="6-digit code"
                variant="outlined"
                value={code}
                onChange={v => this.setCode(v.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.rememberDevice}
                  onChange={e => {
                    this.setState({ rememberDevice: e.target.checked });
                  }}
                  color="primary"
                  name="rememberDeviceCheckbox"
                />
              }
              label="Remember device"
            />
          </Grid>

          <Grid item xs={12}>
            <RouteButton disabled={this.state.loading} type="submit">
              Submit
            </RouteButton>
          </Grid>
          <Grid item md={12} xs={12}>
            <Button
              color="secondary"
              variant="outlined"
              disabled={this.state.loading}
              isCancel
              onClick={this.goBack}
              className="cancelButton"
            >
              Go Back
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}
