export default {
  button: {
    width: 'calc(100vw - 60px) !important',
    maxWidth: '350px',
  },
  buttonText: {
    color: '#fff',
    fontWeight: 700,
  },
};
