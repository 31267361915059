import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const useStyles = makeStyles(styles);

function RouteButton({ children, onClick, type, disabled }) {
  const classes = useStyles();

  return (
    <Button
      type={type || 'button'}
      className={classes.button}
      variant="contained"
      color="secondary"
      onClick={onClick || null}
      disabled={disabled}
      size="large"
    >
      <Typography variant="h5" className={classes.buttonText}>{children}</Typography>
    </Button>
  );
}

RouteButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

export default RouteButton;
