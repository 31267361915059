import { theme } from '@aspida/react-components';
import { ThemeProvider } from '@material-ui/core/styles';
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator, VerifyContact, RequireNewPassword } from 'aws-amplify-react';
import { useQueryParams } from 'hookrouter';
import React from 'react';
import { useAsyncEffect } from 'use-async-effect';
import authorizedPages from 'routes';
import 'regenerator-runtime/runtime';
import SpeedySideBanner from 'assets/images/speedySideBanner.svg';
import amplifyConfig from 'config/amplifyConfig';
import AspidaConfirmSignIn from 'views/confirmSignIn';
import AspidaForgotPassword from 'views/forgotPassword';
import AspidaSignIn from 'views/signin';
import './app.css';

function App() {

  const [queryParams, setQueryParams] = useQueryParams();

  const pendoConfig = {
    sanitizeUrl: (url) => {
      return url.split('?')[0]; // chop off query parameters
    },
  };
  if (window.pendo) {
    window.pendo.initialize(pendoConfig);
  }

  Amplify.configure(amplifyConfig);
  const AuthSignout = async () => {
    const keepMap = {};
    // keep values needed for device recognition
    for (let i = 0; i < window.localStorage.length; i += 1) {
      const key = window.localStorage.key(i);
      const pieces = key.split('.');
      const lastPiecesToKeep = ['deviceKey', 'randomPasswordKey', 'deviceGroupKey'];
      if (pieces[0] === 'CognitoIdentityServiceProvider' && lastPiecesToKeep.indexOf(pieces[pieces.length - 1]) > -1) {
        keepMap[key] = window.localStorage.getItem(key);
      }
    }
    localStorage.clear();
    // set device keys after clearing local storage
    Object.keys(keepMap).forEach(key => {
      localStorage.setItem(key, keepMap[key]);
    });
    await Auth.signOut({ global: true });
    const newQueryParams = queryParams;
    delete newQueryParams.signout;
    setQueryParams({ ...newQueryParams }, true);
  };
  useAsyncEffect(async () => {
    if (queryParams.signout === 'true') {
      await AuthSignout();
    }
  });
  const AppWithAuth = withAuthenticator(
    authorizedPages,
    false,
    [
      <AspidaSignIn override="SignIn" />,
      <AspidaConfirmSignIn override="ConfirmSignIn" />,
      <RequireNewPassword />,
      <VerifyContact />,
      <AspidaForgotPassword override="ForgotPassword" />,
    ],
    null,
  );
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <div className="container">
          <img src={SpeedySideBanner} alt="side-banner" className="sideBanner" />
          <div>
            <AppWithAuth />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
export default App;
