import axios from 'axios';
import { Auth } from 'aws-amplify';
import uniqid from 'uniqid';
import BlobHelper from './blobHelper';

/**
 * Axios instance pointing to the document service
 */
const AxiosConfig = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_URL}/v1`,
  withCredentials: false,
  timeout: 60000,
});

/**
 *  Handles sucessful response form the server. Returns response data object.
 * @param {Object} response
 * @returns data of successful response
 */
function handleSuccess(response) {
  if (response && response.data) {
    const { data } = response;
    return data || response;
  }
}

/**
 * Handles an error response.
 * @returns Returns a error string
 */
async function handleError(error) {
  const err = BlobHelper.isBlob(error) ? await BlobHelper.transformBlob(error.response) : error.response;
  return Promise.reject(err);
}

AxiosConfig.interceptors.request.use(async config => {
  const data = await Auth.currentAuthenticatedUser();
  const token = data.signInUserSession.idToken.jwtToken;
  config.headers.common['x-cognito-identity-token'] = token; // eslint-disable-line no-param-reassign
  config.headers.common['x-request-id'] = uniqid(); // eslint-disable-line no-param-reassign
  return config;
});

AxiosConfig.interceptors.response.use(handleSuccess, handleError);

export default AxiosConfig;
