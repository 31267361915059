export default {

  Auth: {
      // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

      // (required)- Amazon Cognito Region
      region: process.env.REACT_APP_REGION,

      // (optional) - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USER_POOL_ID,

      // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

      // (optional) - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // - Cookie domain (only required if cookieStorage is provided)
        domain: process.env.REACT_APP_COOKIEDOMAIN_URL,
        // (optional) - Cookie path
        path: '/',
        // (optional) - Cookie expiration in days
        expires: 90,
        // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'strict',
        // (optional) - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true,
        httpOnly: true,
      }
    },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
    },
  },
};
