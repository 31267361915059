import to from 'await-to-js';
import axios from './config/axiosConfig';

class Service {
  healthcheck = () => {
    return to(axios.get('/healthcheck'));
  };

  getBaseUrl = () => {
    return process.env.REACT_APP_SERVICE_URL;
  };

  getPeopleById = id => {
    const baseUrl = this.getBaseUrl();

    return to(axios.get(`${baseUrl}/v1/people/${id}`));
  };

  getCustomerPoliciesById = id => {
    const baseUrl = this.getBaseUrl();

    return to(axios.get(`${baseUrl}/v1/customers/${id}/policies`));
  };

  getZendeskToken() {
    const baseUrl = this.getBaseUrl();

    return to(axios.get(`${baseUrl}/v1/zendesk`));
  }

  getZendeskUrl = async (returnToUrl) => {
    const [err, zendeskToken] = await this.getZendeskToken();

    if (err || !zendeskToken) {
      // eslint-disable-next-line no-console
      console.error('Error getting zendesk token', err);
      return;
    }

    const url = `${zendeskToken.authUrl}/access/jwt?jwt=${
      zendeskToken.token
    }&return_to=${encodeURIComponent(returnToUrl)}`;
    return url;
  };
}

export default new Service();
