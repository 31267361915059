import React from 'react';
import 'babel-polyfill';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import App from './app/app';
import './index.css';

window.jsonToQueryString = json => {
  return `?${Object.keys(json)
    .map(key => {
      return `${key}=${json[key]}`;
    })
    .join('&')}`;
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
);
