const roles = {
  rosterAdmin: '183bf7bf-8355-40e5-a9c5-71cb86ce4bbe',
  reportingAdmin: '7cf90f52-df22-4768-b61b-155fd7f46a6f',
  limitedReportingAdmin: '3deae0b1-e488-4f1c-a72a-596c0131da72',
  producer: '26bbb353-96fb-4c13-ab80-f42634c31619',
  producingPrincipalAgent: '06e30b04-9ea2-4b05-b676-a7492eb8d31c',
  nonProducingPrincipalAgent: 'd4530c12-ebdb-40ed-af5c-bf2f851e8f05',
};

export default {
  distributor: [
    roles.rosterAdmin,
    roles.reportingAdmin,
    roles.limitedReportingAdmin,
    roles.producer,
    roles.producingPrincipalAgent,
    roles.nonProducingPrincipalAgent,
  ],
  eApp: [
    roles.producer,
    roles.producingPrincipalAgent,
    roles.nonProducingPrincipalAgent,
    roles.rosterAdmin,
    roles.reportingAdmin,
    roles.limitedReportingAdmin,
  ],
  illustration: [
    roles.producer,
    roles.producingPrincipalAgent,
    roles.nonProducingPrincipalAgent,
    roles.rosterAdmin,
    roles.reportingAdmin,
    roles.limitedReportingAdmin,
  ],
};
