/* eslint-disable no-underscore-dangle */
import React from 'react';
import { SignIn } from 'aws-amplify-react';
import {
  ButtonBase,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  Grid,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import pinkPurpleLogo from '@aspida/react-components/dist/pinkPurple.svg';
import RouteButton from 'components/routeButton';

import './styles.css';

export default class AspidaSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut'];
  }

  state = {
    showPassword: false,
    loading: false,
    isEmail: false,
    isPassword: false
  };

  forgotPasswordClick = () => this.changeState('forgotPassword');

  handleClickShowPassword = () => {
    this.setState(state => ({
      showPassword: !state.showPassword,
    }));
  };

  handleMouseDownPassword = event => event.preventDefault();

  onKeyPressToLowerCase = ev => {
    const modifiedEvent = ev;
    modifiedEvent.target.value = ev.target.value.toLowerCase().trim();
    // eslint-disable-next-line
    const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    this.handleInputChange(modifiedEvent);
    if (modifiedEvent.target.value.match(emailRegex)) {
      this.setState({ isEmail: true, username: modifiedEvent.target.value });
    } else {
      this.setState({ isEmail: false });
    }
  };

  setPassword = ev => {
    this.handleInputChange(ev);
    if (ev.target.value !== '') {
      this.setState({ isPassword: true });
    } else {
      this.setState({ isPassword: false });
    }
  };

  onSubmit = event => {
    event.preventDefault();
    sessionStorage.setItem("__email", this.state.username);
    this.signIn();
    this.setState({ loading: true });
  };

  showComponent() {
    const { showPassword } = this.state;

    return (
      <form onSubmit={this.onSubmit.bind(this)} className="root">
        <img src={pinkPurpleLogo} className="logo" alt="logo" />
        <Grid container spacing={3} direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body1" color="inherit">
              We're in the business of protecting dreams.
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <FormControl variant="outlined" className="input" fullWidth>
              <InputLabel htmlFor="username" className="label">
                Email Address
              </InputLabel>
              <OutlinedInput
                id="username"
                label="Username"
                inputProps={{
                  key: 'username',
                  name: 'username',
                }}
                autoFocus
                onChange={e => {
                  this.onKeyPressToLowerCase(e);
                }}
                style={{ paddingRight: '14px' }}
                labelWidth={105}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className="input" fullWidth>
              <InputLabel htmlFor="password" className="label">
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                inputProps={{
                  key: 'password',
                  name: 'password',
                }}
                type={showPassword ? 'text' : 'password'}
                onChange={e => {
                  this.setPassword(e);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle Password Visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={74}
              />
              <FormHelperText>
                <ButtonBase className="reset" onClick={this.forgotPasswordClick}>
                  Forgot your password?
                </ButtonBase>
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <RouteButton
              disabled={!(this.state.isEmail && this.state.isPassword) || this.state.loading}
              type="submit"
            >
              Log in
            </RouteButton>
          </Grid>
        </Grid>
      </form>
    );
  }
}
