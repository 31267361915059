import React, { useEffect } from 'react';
import { useQueryParams } from 'hookrouter';
import { Auth } from 'aws-amplify';
import service from 'api/service';
import roleTypes from './rolesPermissions';

const AuthedPages = () => {
  const [queryParams] = useQueryParams();

  const clearDeviceKeys = () => {
    for (let i = 0; i < window.localStorage.length; i += 1) {
      const key = window.localStorage.key(i);
      const pieces = key.split('.');
      const piecesToDestroy = ['deviceKey', 'randomPasswordKey', 'deviceGroupKey'];

      if (pieces[0] === 'CognitoIdentityServiceProvider' && piecesToDestroy.indexOf(pieces[pieces.length - 1]) > -1) {
        window.localStorage.removeItem(key);
      }
    }
  };

  const doesPersonHavePermissionToAccessApp = async (person, appName) => {
    const authorizedRoles = new Set();
    switch (appName) {
      case 'distributor': {
        roleTypes.distributor.forEach(role => {
          authorizedRoles.add(role);
        });
        break;
      }
      case 'eApp': {
        roleTypes.eApp.forEach(role => {
          authorizedRoles.add(role);
        });
        break;
      }
      case 'illustration': {
        roleTypes.illustration.forEach(role => {
          authorizedRoles.add(role);
        });
        break;
      }
      case 'customerPortal': {
        const [error, response] = await service.getCustomerPoliciesById(person.id, queryParams.env);

        if (error) {
          return false;
        }

        return response.data.length !== 0;
      }
      default: {
        return false;
      }
    }

    return person.types.find(role => role.active && authorizedRoles.has(role.id));
  };

  const redirect = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const [errorResponse, userObj] = await service.getPeopleById(cognitoUser.attributes['custom:partyId']);

      if (errorResponse || !userObj.id) {
        Auth.signOut({ global: true });
        return;
      }

      if (window.localStorage.getItem('optOutRememberDevice') === 'true') {
        clearDeviceKeys();
      }

      if (queryParams.app) {
        const doesPersonHaveAccess = await doesPersonHavePermissionToAccessApp(userObj, queryParams.app);
        if (!doesPersonHaveAccess) {
          Auth.signOut({ global: true });
          return;
        }
      }

      // Handle Zendesk returns
      if (queryParams.return_to) {
        const zendeskUrl = await service.getZendeskUrl(queryParams.return_to);
        window.location = zendeskUrl;
        return;
      }

      let url = queryParams.redirect ? new URL(queryParams.redirect) : null;

      if (!url) {
        // if the redirect is not present, we need to look at the user's role to determine where to send them
        const isDistributionUser = userObj.types &&
          userObj.types.some((type) => roleTypes.distributor.includes(type.id));
        url =
          isDistributionUser
            ? new URL(process.env.REACT_APP_PRODUCERS_URL)
            : new URL(process.env.REACT_APP_CUSTOMERS_URL);
      }

      window.location = `${url.origin}${url.pathname}`;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    redirect();
  });

  return <></>;
};

export default AuthedPages;
